import {Injectable} from '@angular/core';

@Injectable()
export class RemoteDataService {
  public serviceURL = 'http://admin.synergic.gr:9030/'
  // public serviceURL = 'http://localhost:9000/'

}









