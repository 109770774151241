import { Component } from '@angular/core';
import 'animate.css';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  //https://ng-bootstrap.github.io/#/components/typeahead/examples
  title = 'customer-app';
}
