import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CookieService} from "./cookie.service";
import {RemoteDataService} from '../../remotedata.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  user:any;




  constructor(private http: HttpClient, private cookieService: CookieService, private remoteDataService: RemoteDataService) {
  }

  public currentUser() {
    if (!this.user) {
      this.user = JSON.parse(this.cookieService.getCookie('customerPortalSynergicUser'));
    }
    return this.user;
  }

  login(email: string, password: string) {
    return this.http.post<any>(this.remoteDataService.serviceURL + 'loginCustomer', {email: email, password: password} , httpOptions)
      .pipe(map(user => {
        if (user) {
          this.user = user;
          this.cookieService.deleteCookie('customerPortalSynergicUser');
          this.cookieService.setCookie('customerPortalSynergicUser', JSON.stringify(user), 1);
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    this.cookieService.deleteCookie('customerPortalSynergicUser');
    this.user = null;
    console.log(JSON.parse(this.cookieService.getCookie('customerPortalSynergicUser')));
    // window.location.reload();
  }

  logoutPlay(data) {
    return this.http.post<any>(this.remoteDataService.serviceURL + 'logout', {} , httpOptions)
      .pipe(map(user => {
        return user;
      }));
  }


  addingToSessionManually(data) {
    return this.http.post<any>(this.remoteDataService.serviceURL + 'addingToSessionManually', data  )
      .pipe(map(user => {
        return user;
      }));
  }





  //dataservice


}

