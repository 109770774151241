import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('drawer') public drawer: MatSidenav;
  public activeTab='support';

  constructor(private routes: Router) {

  }


  ngOnInit(): void {
    setTimeout(()=>{
      this.drawer.toggle();
      //this.setActiveTab(this.activeTab);
    },400);
  }


  setActiveTab(tab){
    this.activeTab=tab;
    this.routes.navigate(["dashboard/"+tab+""]);
  }



  logout(){
    this.routes.navigate(['login']);
  }


}
