import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from './auth.service';
import {first} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit ,AfterViewInit {
  loginForm: FormGroup;
  email;
  password;
  submitted = false;
  typePwd="text";
  loading;
  error = '';
  constructor(
    private routes: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService
    ) { }

  ngOnInit(): void {
    this.email='';
    this.password='';
    this.loginForm = this.formBuilder.group({
      email: [this.email, [Validators.required]],
      password: [this.password, Validators.required],
    });
  }
  get f() {
    return this.loginForm.controls;
  }
  changeTypePwd(){
    this.typePwd="password";
  }
  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
  ngAfterViewInit(): void {}


  login() {

    this.routes.navigate(['dashboard']);

    // if (this.loginForm.invalid) {
    //   this.toastr.warning('Η φόρμα σας δεν είναι έγκυρη,παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία', 'warning');
    //   this.validateAllFormFields(this.loginForm);
    //   this.submitted = true;
    //   return;
    // }
    // this.loading = true;
    // this.authenticationService.login(this.f.email.value, this.f.password.value)
    //   .pipe(first())
    //   .subscribe(
    //     httpResponse => { //https://stackoverflow.com/questions/50076352/angular-is-not-sending-the-cookie-received-in-set-cookie-even-if-withcredentials
    //       if (httpResponse.status == 'success') {
    //         this.routes.navigate(['dashboard']);
    //       } else {
    //         this.toastr.error(httpResponse.message, 'Oops!');
    //       }
    //       this.loading = false;
    //     },
    //     error => {
    //       this.error = error;
    //       this.loading = false;
    //     });
  }
}
