<div class="login-form full-height full-width animate__animated animate__bounceInLeft">
  <div class="container full-height full-width">
    <div class="row full-height full-width align-items-center">
      <div class="col-sm-6  d-block d-sm-none">
        <h1>Sign in</h1>
        <img class="anontec-login-img-sm" src="http://support.anontec.net/wp-content/uploads/2020/11/Group-10.svg">
      </div>
      <div class="col-sm-6  sign-in-decoration d-none d-sm-block">
        <h1>Sign in</h1>
        <h3>Support System and Task Manager</h3>
        <img class="anontec-login-img" src="http://support.anontec.net/wp-content/uploads/2020/11/Group-10.svg">
      </div>
      <div class="col-sm-6">
        <form [formGroup]="loginForm" >
          <div class="mb-3">
            <label  class="form-label">Email address<span class="important-star">*</span></label>
            <input formControlName="email"  type="text" class="form-control"  aria-describedby="emailHelp"   />
            <div id="emailHelp" class="form-text">example@mail.com</div>
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">Password<span class="important-star">*</span></label>
            <input formControlName="password" (input)="changeTypePwd()" [type]="typePwd"  class="form-control"  id="exampleInputPassword1" />
          </div>
          <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1">
            <label class="form-check-label" for="exampleCheck1">Remember me</label>
            <span class="example-spacer"></span>
            <button (click)="login()" style="background-color: #aa06c3!important;border-color: #aa06c3" type="submit" class="btn btn-primary">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
