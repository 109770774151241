import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbAlertModule, NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './pages/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
import {ToastrModule} from 'ngx-toastr';
import {AuthenticationService} from './pages/login/auth.service';
import {RemoteDataService} from './remotedata.service';
import {CookieService} from './pages/login/cookie.service';
import {AuthGuard} from './pages/login/auth.guard';
import {HttpClientModule} from '@angular/common/http';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {DataService} from './data.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AutocompleteOffDirective,
    DashboardComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatOptionModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right'
    }),
    NgbPaginationModule, NgbAlertModule

  ],
  providers: [AuthenticationService,RemoteDataService,CookieService,AuthGuard,DataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
