import {Injectable} from '@angular/core';
import {RemoteDataService} from './remotedata.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';


@Injectable()
export class DataService {

  constructor(private http: HttpClient,
              private remoteDataService: RemoteDataService) {
  }


  // getAllTasksByOrganization(data) {
  //   this.http.post(this.remoteDataService+'getAllTasksByOrganization' , data).pipe(map(data => {})).subscribe(result => {
  //     console.log(result);
  //   });
  // }


  getUserById(data) {
    return this.http.post<any>(this.remoteDataService.serviceURL + 'getUserById', data).pipe(map(
      (response) => {
        return response;
      }
    ))
  }


  getAllTasksByOrganization(data) {
    return this.http.post<any>(this.remoteDataService.serviceURL + 'getAllTasksByOrganization', data).pipe(map(
      (response) => {
        return response;
      }
    ))
  }



  getAccounts(data) {
    return this.http.post<any>(this.remoteDataService.serviceURL + 'getAccounts', data).pipe(map(
      (response) => {
        return response;
      }
    ))
  }


  addTask(data){
    return this.http.post<any>(this.remoteDataService.serviceURL + 'addTask', data).pipe(map(
      (response) => {
        return response;
      }
    ))
  }


  getTaskById(data){
    return this.http.post<any>(this.remoteDataService.serviceURL + 'getTaskById', data).pipe(map(
      (response) => {
        return response;
      }
    ))
  }

  addComment(data){
    return this.http.post<any>(this.remoteDataService.serviceURL + 'addComment', data).pipe(map(
      (response) => {
        return response;
      }
    ))
  }


  getCommentsTask(data){
    return this.http.post<any>(this.remoteDataService.serviceURL + 'getCommentsTask', data).pipe(map(
      (response) => {
        return response;
      }
    ))
  }


  public getTypeStyles(status) {
    if (status == "task") {
      return { "color": "darkblue" };
    }
    else if (status == "new requirement") {
      return { "color": "deepskyblue" };
    }
    else if (status == "support") {
      return { "color": "red" };
    }
    else if (status == "bug") {
      return { "color": "orange" };
    }
    else if (status == "change requirement") {
      return { "color": "grey" };
    }
    else if (status == "data correction") {
      return {'color': '#c738e4'};
    }
    else if (status == "incident") {
      return { "color": "red" };
    }
  }



  public getStatusStyles(status) {
    if (status == "new") {
      return { "color": "darkblue" };
    }
    else if (status == "todo") {
      return { "color": "deepskyblue" };
    }
    else if (status == "canceled") {
      return { "color": "red" };
    }
    else if (status == "inprogress") {
      return { "color": "orange" };
    }
    else if (status == "paused") {
      return { "color": "grey" };
    }
    else if (status == "waiting") {
      return {'color': '#c738e4'};
    }
    else if (status == "done") {
      return { "color": "green" };
    }
    else if (status == "testing") {
      return { "color": "#51e451" };
    }
    else if (status == "closed") {
      return { "color": "black" };
    }
  }

  public getPriorityBorder(priority) {
    if (priority == "urgent") {
      return {
        'color': 'rgb(255,0,0)',
      };
    }
    else if (priority == "low") {
      return {
        'color': 'rgb(128, 128, 128)'
      };
    }
    else if (priority == "medium") {
      return {
        'color': 'rgb(1, 1, 1)'
      };
    }
    else if (priority == "high") {
      return {
        'color': 'rgb(255, 165, 0)'
      };
    }
  }

  public getPriorityStyles(priority) {
    if (priority == "urgent") {
      return {
        'color': 'rgb(255,0,0)', 'font-size': '16px',
      };
    }
    else if (priority == "low") {
      return {
        'color': 'rgb(128, 128, 128)', 'font-size': '16px',
      };
    }
    else if (priority == "medium") {
      return {
        'color': 'rgb(1, 1, 1)', 'font-size': '16px',
      };
    }
    else if (priority == "high") {
      return {
        'color': 'rgb(255, 165, 0)', 'font-size': '16px',
      };
    }
  }




}

