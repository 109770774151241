<mat-drawer-container class="example-container" [hasBackdrop]="false">
  <mat-drawer #drawer [mode]="'side'">
    <div class="div-img-logo">
      <img class="anontec-login-img" src="http://support.anontec.net/wp-content/uploads/2020/11/Group-10.svg">
    </div>
    <div class="menu-options">
      <div (click)="setActiveTab('support')"  [ngClass]="this.activeTab=='support' ?'option-menu-active':'option-menu'">
        <label class="label-option">Support</label>
        <span class="example-spacer"></span>
        <span class="material-icons " [ngClass]="this.activeTab=='support' ?'active-icon-menu-option-active':'active-icon-menu-option'"  >keyboard_arrow_right</span>
      </div>
      <div (click)="setActiveTab('my-profile')" [ngClass]="this.activeTab=='my-profile' ?'option-menu-active':'option-menu'">
        <label class="label-option">My profile</label>
        <span class="example-spacer"></span>
        <span class="material-icons " [ngClass]="this.activeTab=='my-profile' ?'active-icon-menu-option-active':'active-icon-menu-option'" >keyboard_arrow_right</span>
      </div>
      <div (click)="setActiveTab('Services')" [ngClass]="this.activeTab=='Services' ?'option-menu-active':'option-menu'">
        <label class="label-option">Services</label>
        <span class="example-spacer"></span>
        <span class="material-icons " [ngClass]="this.activeTab=='Services' ?'active-icon-menu-option-active':'active-icon-menu-option'">keyboard_arrow_right</span>
      </div>
      <div (click)="setActiveTab('Projects')" [ngClass]="this.activeTab=='Projects' ?'option-menu-active':'option-menu'">
        <label class="label-option">Projects</label>
        <span class="example-spacer"></span>
        <span class="material-icons " [ngClass]="this.activeTab=='Projects' ?'active-icon-menu-option-active':'active-icon-menu-option'" >keyboard_arrow_right</span>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content style="background-color: #e5e9ec;">
    <div class="toolbar-app">
      <div class="row full-height full-width align-items-center m-0">
        <div class="col-sm-6  d-block d-sm-none"></div>
        <div class="col-sm-6  div-decoration-left d-none d-sm-block">
          <span>Software Technology &</span>
          <span>Information Systems Services</span>
        </div>
        <div class="col-sm-6  d-sm-none d-none d-md-block ">
          <div class="div-decoration-right">
            <div class="right-div-dec-span" style="border-right: unset">2022-04-01 15:39:54</div>
            <div class="right-div-dec-span user-div" >Dimitris Papoutsis<span class="span-org">Test Organization</span></div>
            <div class="right-div-dec-span logout-div" ><span (click)="logout()" class="material-icons" style="cursor: pointer">logout</span>LOGOUT</div>
          </div>

        </div>
      </div>
    </div>
    <div class="routing-div">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
